/* eslint no-undef:1 */

/* ==================================================
Copyright year:

Updates copyright year with JS
===================================================*/
var currentYear  = new Date().getFullYear();
$('#copyright').text(currentYear);


/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
var $mainNav = $('.overlay-nav');
var $body = $('body');
var $navToggle = $('.nav-toggle');

$navToggle.click(function(e) {
  e.preventDefault();

  $mainNav.addClass('open');
  $body.addClass('nav-open');
  $navToggle.addClass('hide');

  Foundation.Motion.animateIn($mainNav, 'slide-in-right fast');
});

$('.overlay-nav .close').click(function(e) {
  e.preventDefault();

  $mainNav.removeClass('open');
  $body.removeClass('nav-open');
  $navToggle.removeClass('hide');
  Foundation.Motion.animateOut($mainNav, 'slide-out-right fast');
});

$('.overlay-nav').on('touchmove', function(e) {
  e.preventDefault();
});


/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/

const path = location.pathname.substring(1);

if (path) {
  $(`nav a[href$="${path}"]`).parent().addClass('selected');
  $(`nav a[href$="${path}"]`).parents('.dropdown').addClass('selected');

  if(path.includes('work/')) {
    $(`nav a[href="/work/"]`).parent().addClass('selected');
  }
}

/* ==================================================
Mobile Nav Dropdown List:

Toggles animated display of the next nav element
after clicking parent icon
===================================================*/

$('.dropdown .arrow-icon').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('open');
  const trigger = $(this).parent().next('.list');

  const $toggleNext = new Foundation.Toggler(trigger, {
    animate: 'fade-in fade-out'
  });
  $(trigger).foundation('toggle');
});


/* ==================================================
Toggle element:

Toggles animated display of element with an id that
matches the 'data-toggle-trigger' of the clicked
element. The default animation is slide in and
slide out.
===================================================*/

$('a[data-toggle-trigger]').click(function (e) {
  e.preventDefault();
  const triggerId = $(this).data('toggle-trigger');
  const toggleItem = $(`#${triggerId}`);

  $(this).toggleClass('open');

  const $toggleElement = new Foundation.Toggler(toggleItem, {
    animate: 'animate-in animate-out',
  });

  toggleItem.foundation('toggle');
});


/* ==================================================
Sliders:

Slick carousel setup and initialization
===================================================*/

$('.hero-slider').slick({
  // adaptiveHeight: true,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 4000,
  cssEase: 'linear',
  fade: true,
  speed: 1000,
  pauseOnHover: false,
  draggable: false,
});


/* ==================================================
Magnific:

Magnific is used for lightbox style popups.

.image-link is for displaying larger versions of
images from a a link.

.video-link is for displaying videos from a link.
Common providers like Youtube and Vimeo will
stop playing when window is closed.
===================================================*/

// $('.image-link').magnificPopup({ type: 'image' });
// $('.video-link').magnificPopup({ type: 'iframe' });
